<template>
<div>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20 pt-0">
            <v-col xl="12" lg="12" sm="8" class="mx-auto">
                <checkmong-home-bar title="ภาพรวมข้อมูลผู้ป่วย"></checkmong-home-bar>
                <v-row style="">

                    <v-col xl="4" lg="6" sm="12" style="text-align: center">
                        <v-row>
                            <v-col xl="12" lg="12" sm="12" style="text-align: center">
                                <v-card>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5">{{total_patient}}</v-list-item-title>
                                            <v-list-item-subtitle>จำนวนผู้ป่วยในการดูแลทั้งหมด</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                            <v-col xl="4" lg="6" sm="12" style="text-align: center">
                                <v-card>
                                    <v-card-text>
                                        <BarChartB :chartData="chartData_b" />
                                        อายุ
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col xl="12" lg="12" sm="12" style="text-align: center">
                                <v-card>
                                    <v-card-text>
                                        <BarChart :chartData="chartData_a" />
                                        ช่วงคะแนน
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col xl="4" lg="6" sm="12" style="text-align: center">
                        <v-card>
                            <v-card-text>
                                <DoughnutChart :chartData="chartData_c" />
                                สัดส่วนโรคประจำตัว
                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
        <br>
        <br>
        <br>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center " style="padding-top: 15px">
            <v-col lg="3" sm="8" class="mx-auto" style="text-align: left;">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-col>

        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-left">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl ml-6">
                    <v-icon small>west</v-icon>
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import BarChart from '@/components/Bar.vue'
import BarChartB from '@/components/BarB.vue'
import DoughnutChart from '@/components/Doughnut.vue'
// import BubbleChart from '@/components/Bubble.vue'
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        BarChart,
        // BubbleChart,
        BarChartB,
        DoughnutChart
    },
    data: () => ({
        total_patient: 0,
        arrayEvents: null,
        chartData: null,
        chartData_a: {
            labels: [
                '0-3',
                '4-12',
                '13-27'
            ],
            datasets: [{
                label: 'จำนวนคนต่อช่วงคะแนน',
                backgroundColor: '#f87979',
                // data: [40, 10, 5, 40+5]
                data: [0, 0, 0, 0]
            }]
        },
        query: '',
        chartData_b: {
            labels: [
                '< 40',
                '40-49',
                '50-59',
                '60-69',
                '70-79',
                '80-89',
                '> 89',
            ],
            datasets: [{
                label: 'คะแนนเฉลี่ยที่ได้ต่อช่วงอายุ',
                backgroundColor: '#f87979',
                // data: [5, 5, 10, 15, 20, 20, 0, 30]
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
            }]
        },
        chartData_c: {
            labels: ['ความดันสูง', 'ไขมันสูง', 'อัลไซเมอร์', 'โรคหัวใจหรือหลอดเลือดสมอง', 'เบาหวาน', 'หัวใจเต้นผิดจังหวะ', 'ซึมเศร้า'],
            datasets: [{
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#596CFF', '#FEDE00', '#1F98BD'],
                // data: [5, 5, 30, 20,10,10,20]
                data: [0, 0, 0, 0, 0, 0, 0]
            }]
        },
    }),
    beforeCreate(){
        // this.getValueFrom()
        // this.query = this.$route.query
            if(this.$route.query.from_line == '1'){
                this.$router.push("/passcode_check?&to=dashboard_summary");
            }
    },

    mounted() {
        this.getUserInfo()
        
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        getValueFrom() {

            this.query = this.$route.query
            if(this.query.from_line == '1'){
                this.$router.push("/passcode_check?&to=dashboard_summary");
            }
            //console.log('this.query',this.query)
        },
        updateChartData() {
            this.total_patient = this.chartData.total_patient
            this.chartData_a.datasets[0].data = this.chartData.chart_data_a
            this.chartData_b.datasets[0].data = this.chartData.chart_data_b
            this.chartData_c.datasets[0].data = this.chartData.chart_data_c
            //console.log('zzz', this.chartData_a)

        },
        async getUserInfo() {
            //console.log('this.$store.state.login_detail.user_id:', this.$store.state.login_detail.user_id)
            await Vue.axios.post(APIURL + '/get_chart_data', {
                "user_id": this.$store.state.login_detail.user_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                // //console.log('get_chart_data',response)
                if (response.data) {

                    this.active_waiting = false
                    this.chartData = response.data
                    //console.log('this.chartData', this.chartData)
                    this.updateChartData()
                    // this.$store.commit('setUserInfo',response.data);
                    // this.province = response.data.province
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                    // this.$router.push('/home_checkmong')
                } else {
                    this.active_waiting = false

                }

            })

        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-main__wrap {
    padding-bottom: 10px;
}
</style>
