<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 250
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      labels:{
        type: Array,
        default: () => []
      },
      datasets:{
        type: Array,
        default: () => []
      }
    }
  },
  data() {
    return {
      // chartData: {
      //   labels: [
      //     '0-3',
      //     '4-12',
      //     '13-26'
      //   ],
      //   datasets: [
      //     {
      //       label: 'ช่วงคะแนนต่อจำนวนคน',
      //       backgroundColor: '#f87979',
      //       // data: [40, 10, 5, 40+5]
      //       data: [0, 0, 0, 0]
      //     }
      //   ]
      // },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
